import React, { useContext, useState } from 'react';
import { Logger } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Collapse from '@mui/material/Collapse';

//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton  from '@mui/material/IconButton';


import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import IconButtonNewWindow from 'pac-responsive-ui-framework/core/IconButtonNewWindow';
import IconButtonFavorite from './IconButtonFavorite';
//import IconButtonInfo from './IconButtonInfo';

import IconButtonConfluence from './IconButtonConfluence';
import Icon from './Icon';

import { useHistory } from "react-router-dom";
//import { parseHTMLtoJSX } from 'pac-responsive-ui-framework/core/HelperHTML';
import { constants } from 'assets/Config.js';
import AppContext from 'pac-responsive-ui-framework/core/AppContext';
import { getURLParams } from 'data/ReportList';

const logger = new Logger('CardReport');

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '8px 8px 8px 8px', 
    border: '1px solid #ccc'
  },
  content: {
    paddingLeft: '20px',
    paddingRight: 0
  },
  actions: {
    marginTop: "-20px"
  },
  category: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#7A7A7A',
    marginBottom: '8px', 
    paddingLeft: '16px',
    position: "Relative",
    top: 4
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
    marginLeft: "16px"
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
    flex: 1,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    marginRight: '6px'
  },
  tags:{
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#333',
    flex: 1
    
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export default function CardReport(props) {
  logger.debug("props", props);
  const app_context = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  let history = useHistory();
  var debug_mode = false;
  if (process.env.REACT_APP_STAGE == 'dev') {
    debug_mode = true;
  }

  
  //const [open, setOpen] = React.useState(false);

  const handleClick = (name) => (event) => {
    logger.debug("handleClick", name);
    history.push(name);
  };
  /*
  const handleInfoClick = (event) => {
    setOpen(!open);
    event.stopPropagation();
  };
  */

  const handleExpandClick = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
    
  };
  /*
  // Default images
  let image = constants.assets_path + "thumb1.jpg";
  if (props.image) {
    try {
      image = constants.assets_path + props.image;
    } catch (e) {
      // Stick with default image
    }
  }
  */


 
  
  

  // Environment tags
  let report_environment_tags;
  var report_type;
  let customer_icon;
   
  const environment_style = {
    dev: { color: '#fff', background: '#cc3232' },
    test: { color: '#fff', background: '#e7b416' },
    prod: { color: '#fff', background: '#99c140' },
    prd: { color: '#fff', background: '#99c140' },
  };
  if (debug_mode) {
    if (props.report_environments) {
      report_environment_tags = props.report_environments.map((env, key) => (
        <Button
          key={key}
          variant="outlined"
          size="small"
          style={{
            textTransform: 'none',
            padding: '0px 4px',
            minWidth: '40px',
            margin: '2px',
            lineHeight: '1.2em',
            color: environment_style[env]?.color || '#000',
            backgroundColor: environment_style[env]?.background || '#ddd',
          }}
          onClick={() => history.push(`/search/env:${env}`)}
        >
          {env}
        </Button>
      ));
    }
    
    if (process.env.REACT_APP_STAGE == 'dev' || process.env.REACT_APP_STAGE == 'test') {
      report_type =  props.report_type;
    }
    
    if (props.customer_icon) {
      try {
        customer_icon = <img src={"https://origins-assets.nextcloud.aero/operator-logos/" + props.customer_icon} 
            title={props.customer_icon}
            style={{ position: 'relative', top: 10, width: 32, height: 32 }} /> ;
      } catch (e) {
        // Stick with default
      }
    }
  }
  
  var title_icon = "flight--roster"; // default title icon
  if (props.title_icon) title_icon = props.title_icon;

  // Card Content Renderer
  const renderCategoryAndTitle = () => (
    <CardContent className={classes.content}>
      <table style={{width: "100%", height: "80px"}}>
        <tbody>
          <tr>
            <td style={{verticalAlign: "middle"}}>
              <Icon name={title_icon} />
            </td>
            <td width="100%" style={{verticalAlign: "middle"}}>
              {props.category && (
                <Typography variant="body2" className={classes.category}>
                  {props.category} {/* Display category inside the card */}
                </Typography>
              )}
              <div className={classes.titleWrapper} >
                
                <Typography variant="body1" className={classes.title}>
                  {props.title}
                </Typography>
              </div>
            </td>
            <td style={{textAlign: "right", verticalAlign: "middle"}}>
              <div className={classes.iconWrapper}>
                <IconButtonFavorite title={props.title} favorite={props.favorite} />
                <IconButtonNewWindow href={props.href + getURLParams(app_context)} />
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
       
    </CardContent>
    
  );
  
    // Dynamically set the card height based on expanded state
  const cardStyle = {
    width: app_context.state.nav_open ? "400px" : "400px",
    transition: "height 0.3s ease",
    height: expanded ? "180px" : "120px",
  };



  // Action Area
  const card_action_area =
    props.target === "_blank" ? (
      <a href={props.href + getURLParams(app_context)} target="_blank" rel="noreferrer noopener">
        <CardActionArea>{renderCategoryAndTitle()}</CardActionArea>
      </a>
    ) : (
      <CardActionArea onClick={handleClick(props.href)}>
        {renderCategoryAndTitle()}
      </CardActionArea>
    );

  var card_style= {width: "400px"};
  if (app_context.state.nav_open) card_style= {width: "498px"};
  
  return (
    <React.Fragment>
       <Box minHeight={10} style={{position: 'relative', top: '8px', paddingLeft: '12px' }}>
        {report_type}
      </Box>
      <Box style={{ position: 'relative', top: '8px', paddingLeft: '12px' }}>
        {report_environment_tags} {customer_icon}
      </Box>
      <Card className={classes.card} square variant="outlined" style={cardStyle}>
        {props.target === "_blank" ? (
          <a href={props.href + getURLParams(app_context)} target="_blank" rel="noreferrer noopener">
            <CardActionArea>{renderCategoryAndTitle()}</CardActionArea>
          </a>
        ) : (
          <CardActionArea onClick={() => history.push(props.href)}>
            {renderCategoryAndTitle()}</CardActionArea>
        )}
        <CardActions className={classes.actions} />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {props.subtitle && (
              <Tooltip title={props.subtitle} arrow>
                <Typography
                  noWrap
                  variant="body2"
                  style={{ marginBottom: '4px', fontSize: '14px', color: '#000', textOverflow: "ellipsis"}}
                >
                  {props.subtitle.split(' ').length > 6
                    ? `${props.subtitle.split(' ').slice(0, 9).join(' ')}...`
                    : props.subtitle}
                </Typography>
              </Tooltip>
            )}
            {props.tags && props.tags.length > 0 ? (
              <div style={{ marginTop: '0px' }}>
                {props.tags.map((tag, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    style={{
                      textTransform: 'none',
                      display: 'inline-block',
                      marginRight: '8px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      color: '#133959',
                      cursor: 'pointer',
                    }}
                    onClick={() => history.push(`/search/${tag}`)}
                  >
                    {tag}
                  </Typography>
                ))}
              </div>
            ) : (
              <f />
            )}
          </CardContent>
        </Collapse>
      </Card>
    </React.Fragment>
  );
  
  /**
   * old code
   */
  return (
    <React.Fragment>
       <Box minHeight={10} style={{position: 'relative', top: '8px', paddingLeft: '12px' }}>
        {report_type}
      </Box>
      <Box style={{ position: 'relative', top: '8px', paddingLeft: '12px' }}>
        {report_environment_tags} {customer_icon}
      </Box>
      <Card className={classes.card} square variant="outlined" style={card_style}>
        {card_action_area}
        <CardActions className={classes.actions} />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            foo
          </CardContent>
        </Collapse>
      </Card>
    </React.Fragment>
  );
}
