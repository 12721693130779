/**
 * https://www.npmjs.com/package/react-awesome-query-builder
 * https://codesandbox.io/p/github/lbrdar/react-awesome-query-builder/master?file=%2Fpackages%2Fexamples%2Fdemo%2Findex.tsx%3A29%2C34-29%2C49
 * https://github.com/ukrbublik/react-awesome-query-builder/issues/190
 * https://jsonlogic.com/
 */

import React, { useState, useCallback } from "react";
import { Logger }           from 'aws-amplify';
//import { Query, Builder, Utils as QbUtils } from "react-awesome-query-builder";

import { QueryBuilder, formatQuery, OptionGroup  } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
// see https://react-querybuilder.js.org/demo#addRuleToNewGroups=false&autoSelectField=true&autoSelectOperator=true&debugMode=false&disabled=false&enableDragAndDrop=false&independentCombinators=false&justifiedLayout=false&listsAsArrays=false&parseNumbers=false&resetOnFieldChange=true&resetOnOperatorChange=false&showBranches=false&showCloneButtons=false&showCombinatorsBetweenRules=false&showLockButtons=false&showNotToggle=false&showShiftActions=false&validateQuery=false


import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// For AntDesign widgets only:
//import AntdConfig from "react-awesome-query-builder/lib/config/antd";
//import "antd/dist/antd.css"; // or import "react-awesome-query-builder/css/antd.less";
// For MUI 4/5 widgets only:
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
//import MuiConfig from 'react-awesome-query-builder/lib/config/mui';
// For Bootstrap widgets only:
//import BootstrapConfig from "react-awesome-query-builder/lib/config/bootstrap";

import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles

// Choose your skin (ant/material/vanilla):
const InitialConfig = MaterialConfig; // or MaterialConfig or MuiConfig or BootstrapConfig or BasicConfig

//const {queryBuilderFormat, jsonLogicFormat, queryString, mongodbFormat, sqlFormat, getTree, checkTree, loadTree, uuid, loadFromJsonLogic} = QbUtils;

const logger = new Logger('QueryBuilder1');

logger.debug("InitialConfig", InitialConfig);
// You need to provide your own config. See below 'Config format'


const attribute_data = [
  
 
  { source: "dl_pac_origins_aircraft_v1.logs", label: "Aircraft Logs", columns: 
    [
      { name: "icao", label: "A/C Logs Operator ICAO Code", description: ""},
      { name: "registration_name", label: "A/C Logs Registration Name", description: ""},
      
    ]
  },
  { source: "dl_pac_origins_pacwisplive_v1.wisp_session_connect", label: "WISP Session", columns: [
     { name: "eventtime", label: "WISP Session Event Time", description: ""},
     { name: "icao", label: "WISP Session Operator ICAO Code", description: ""},
     { name: "correlationdata.sessionid", label: "WISP Correlation Session ID", description: ""},
     { name: "data.clientinformation.username", label: "WISP Session Username", description: ""},
     { name: "data.clientinformation.emailaddress", label: "WISP Session Email Address", description: ""},
     { name: "data.clientinformation.accountid", label: "WISP Session Account ID", description: ""},
     { name: "data.subscribername", label: "WISP Session Subscriber Name", description: ""},
     { name: "data.transactioninformation.transactionid", label: "WISP Session Transaction ID", description: ""},	
     { name: "data.transactioninformation.transactiontime", label: "WISP Session Transaction Time", description: ""},
     { name: "data.transactioninformation.voucherbatch", label: "WISP Session Voucher Batch", description: ""},	
     { name: "data.transactioninformation.transactionprice", label: "WISP Session Transation Price", description: ""},	
     { name: "data.deviceinformation.ip", label: "WISP Session Device IP", description: ""},	
     { name: "data.deviceinformation.mac", label: "WISP Session Device MAC", description: ""},	
     { name: "data.captchapassed", label: "WISP Session Captcha Passed", description: ""},	
     { name: "data.sessionid", label: "WISP Session ID", description: ""},	
     { name: "data.icao", label: "WISP Session ICAO ", description: ""},
     { name: "data.planinformation.planapplications", label: "WISP Session Plan Applications", description: ""},	
     { name: "data.planinformation.plandevicetype", label: "WISP Sessio Plan Device type", description: ""},	
     { name: "data.planinformation.planvouchertype", label: "WISP Session Plan Voucher Type", description: ""},	
     { name: "data.planinformation.plancurrency", label: "WISP Session Plan Currency", description: ""},	
     { name: "data.planinformation.planid", label: "WISP Session Plan ID", description: ""},	
     { name: "data.planinformation.planroamingpartner", label: "WISP Session Plan Roaming Partner", description: ""},	
     { name: "data.planinformation.planpaymentvendorname", label: "WISP Session Plan Payment Vendor", description: ""},	
     { name: "data.planinformation.plantype", label: "WISP Session Plan Type", description: ""},	
     { name: "data.planinformation.plansubscription", label: "WISP Session Plan Subscription", description: ""},	
     { name: "data.planinformation.plantiername", label: "WISP Session Plan Tier Name", description: ""},	
     { name: "data.planinformation.plantimelimit", label: "WISP Session Plan Time Limit", description: ""},	
     { name: "data.planinformation.planpayment", label: "WISP Session Plan Payment", description: ""},	
     { name: "data.planinformation.plantier", label: "WISP Session Plam Tier", description: ""},	
     { name: "data.planinformation.planbytelimit", label: "WISP Session Plan Byte Limit", description: ""},	
     { name: "data.flightinformation.associated_arrive_airport_icao", label: "WISP Session Arrive Airport", description: ""},	
     { name: "data.flightinformation.departuretime", label: "WISP Session Departure Time", description: ""},	
     { name: "data.flightinformation.associated_arrive_airport_iata", label: "WISP Session Arrival IATA", description: ""},	
     { name: "data.flightinformation.associated_takeoff_time_utc", label: "WISP Session Takeoff Time (UTC)", description: ""},	
     { name: "data.flightinformation.associated_flight_excluded", label: "WISP Session Flight Excluded", description: ""},	
     { name: "data.flightinformation.associated_flight_inop", label: "WISP Session Flight INOP", description: ""},	
     { name: "data.flightinformation.destinationcity", label: "WISP Session Destination City", description: ""},	
     { name: "data.flightinformation.associated_depart_airport_icao", label: "WISP Session Departure ICAO", description: ""},	
     { name: "data.flightinformation.flightnumber", label: "WISP Session Flight Number", description: ""},	
     { name: "data.flightinformation.associated_depart_gate_time_utc", label: "WISP Session Depart Gate UTC", description: ""},	
     { name: "data.flightinformation.associated_flight_number", label: "WISP Session Associated Flight Number", description: ""},	
     { name: "data.flightinformation.associated_flight_id", label: "WISP Session Flight ID", description: ""},	
     { name: "data.flightinformation.associated_landing_time_utc", label: "WISP Session Landing Time (UTC)", description: ""},	
     { name: "data.flightinformation.associated_depart_airport_iata", label: "WISP Session Associated Departure IATA", description: ""},	
     { name: "data.flightinformation.associated_arrive_gate_time_utc", label: "WISP Session Associated Arrival Gate Time (UTC)", description: ""},	
     { name: "data.flightinformation.origincity", label: "WISP Session Origin City", description: ""},	
     { name: "data.flightinformation.tailnumber", label: "WISP Session Registration Name", description: ""},	
     { name: "data.all.device.mac", label: "WISP Session MAC Address", description: ""},	
     { name: "data.all.device.user_agent", label: "WISP Session User Agent", description: ""},	
     { name: "data.all.device.device_type", label: "WISP Session Device Type", description: ""},	
     { name: "data.all.wisp.connection_url", label: "WISP Session Connection URL", description: ""},	
     { name: "data.all.wisp.app", label: "WISP Session App", description: ""},	
     { name: "data.all.wisp.acct_username", label: "WISP Session account username", description: ""},	
     { name: "data.all.wisp.acct_id", label: "WISP Session Account ID", description: ""},	
     { name: "data.all.wisp.captcha_passed", label: "WISP Session Captcha Passed", description: ""},	
     { name: "data.all.wisp.acct_email", label: "WISP Session Account Email", description: ""},	
     { name: "data.all.connection.username", label: "WISP Connection Username", description: ""},	
     { name: "data.all.connection.calledstationid", label: "WISP Connection Called Station ID ", description: ""},	
     { name: "data.all.connection.callingstationid", label: "WISP Connection Calling Station ID", description: ""},	
     { name: "data.all.connection.realm", label: "WISP Connection Realm ", description: ""},	
     { name: "data.all.connection.framedipaddress", label: "WISP Connection Framed IP Address", description: ""},	
     { name: "data.all.connection.acctstarttime", label: "WISP Connection Account Start Time", description: ""},	
     { name: "data.all.connection.nasipaddress", label: "WISP Conneciton NAS IP Address", description: ""},	
     { name: "data.all.connection.acctsessionid", label: "WISP Connection Account Session ID", description: ""},	
     { name: "data.all.connection.nasporttype", label: "WISP Connection NAS Port Type", description: ""},	
     { name: "data.all.connection.acctstatustype", label: "WISP Connection Account Status Type", description: ""},	
     { name: "data.all.connection.acctuniqueid", label: "WISP Connection Account Unique ID", description: ""},	
     { name: "data.all.flight.dep_time", label: "WISP Flight Departure Time", description: ""},	
     { name: "data.all.flight.org_iata", label: "WISP Flight Departure IATA", description: ""},	
     { name: "data.all.flight.dest_iata", label: "WISP Flight Arrival IATA", description: ""},	
     { name: "data.all.flight.dest_icao", label: "WISP Flight Arrival ICAO ", description: ""},	
     { name: "data.all.flight.tailno", label: "WISP Flight Registration Name ", description: ""},	
     { name: "data.all.flight.arr_time", label: "WISP Flight Arrival Time ", description: ""},	
     { name: "data.all.flight.org_icao", label: "WISP Flight Departure ICAO", description: ""},	
     { name: "data.all.flight.airline_icao", label: "WISP Flight Operator ICAO Code", description: ""},	
     { name: "data.all.flight.flightno", label: "WISP Flight Number ", description: ""},	
     { name: "data.all.plan.plan_data", label: "WISP Plan Data ", description: ""},	
     { name: "data.all.plan.tsp", label: "WISP Plan TSP ", description: ""},	
     { name: "data.transactioninformation.cardtype", label: "WISP Transation Card Type", description: ""},	
     { name: "data.deviceinformation.useragent", label: "WISP Device User Agent", description: ""},	
     { name: "data.planinformation.planname", label: "WISP Plan Name ", description: ""},	
     { name: "data.planinformation.plansubscribername", label: "WISP Plan Subscriber Name ", description: ""},	
     { name: "data.rasconnectionid", label: "WISP RAS Connection ID ", description: ""},
     { name: "data.event", label: "WISP Event", description: ""},	
     { name: "data.flightinformation.associated_tailno", label: "WISP Flight Associated Tail Number ", description: ""},	
     { name: "data.transactioninformation.paymenttype", label: "WISP Transation Payment Type", description: ""},	
     { name: "data.flightinformation.associated_icao", label: "WISP Flight Associated ICAO Code", description: ""},	
     { name: "data.deviceinformation.countryofresidence", label: "WISP Device Country of Residence", description: ""},	
     { name: "data.planinformation.planprice", label: "WISP Plan Price", description: ""},	
     { name: "data.transactioninformation.pnr", label: "WISP Transation PNR", description: ""},	
     { name: "data.dotransactioninformation.isguest", label: "WISP Transation Is Guest ", description: ""},	
     { name: "dt",label: "WISP Session dt", description: ""}, 
     { name: "eventtime - data.flightinformation.departuretime",label: "WISP Event Delta", description: ""}, 
     
    ]
  },
  { source: "dl_pac_dp_fds_raw_prod_v1.mst_fds_flight_events", label: "FDS Flight Events", columns: [
      { name: "operatoricaocode", label: "FDS Operator ICAO Code", descrption: "" },
      { name: "aircraftregistrationnumber", label: "FDS Registration Name", descrption: "" },
      { name: "flightnumber", label: "FDS Flight Number", descrption: "" },
      { name: "airportiatadeparturecode", label: "FDS IATA Departure Code", descrption: "" },
      { name: "airportiatadeparturecode", label: "FDS IATA Departure Code", descrption: "" },
      { name: "airporticaodeparturecode", label: "FDS ICAO Departure Code", descrption: "" },
      { name: "airportiataarrivalcode", label: "FDS IATA Arrival Code", descrption: "" },
      { name: "airporticaoarrivalcode", label: "FDS ICAO Arrival Code", descrption: "" },
      { name: "flightroutecode", label: "FDS Flight Route Code", descrption: "" },
      { name: "departgatedate", label: "FDS Departure Gate Time", descrption: "" },
      { name: "takeoffdate", label: "FDS Takeoff Time", descrption: "" },
      { name: "landingdate", label: "FDS Landing Time", descrption: "" },
      { name: "arrivegatedate", label: "FDS Arrival Gate Time", descrption: "" },
      { name: "estimatedlandingdate", label: "FDS Estimated Landing Time", descrption: "" },
      { name: "flightstatecode", label: "FDS Flight Status", descrption: "" }
    ]
  },
  { source: "tsyslog_bite", label: "BITE Events", columns: 
    [
     // { name: "flight_date", label: "BITE Flight Date", description: "The date that BITE flight data occurred.  Dates are based on takeoff time."},
    //  { name: "flight_number", label: "BITE Flight Number", description: "The flioght number associated with the flight. This usually cooresponds to the flight route and is not unique to the dat, or to the operator."},
    //  { name: "flight_number_raw", label: "BITE Raw Flight Number", description: "The flioght number associated with the flight. The raw format may include the operator ICAO Code."},
      { name: "departure_city", label: "BITE Departure City", description: "" },
      { name: "arrival_city", label: "BITE Arrival City", description: "" },
      { name: "flight_duration", label: "BITE Flight Durration", description: "" },
    //  { name: "estimated_arrival", label: "BITE Estimated Arrival", description: "" },
    //  { name: "timestamp_ground", label: "BITE Ground Timestamp", description: "" },
    //  { name: "timestamp_departure", label: "BITE Departure Timestamp", description: "" },
    //  { name: "timestamp_arrival", label: "BITE Arrival Timestamp", description: "" },
      { name: "system", label: "BITE Aircraft System", description: "" },
      { name: "build", label: "BITE Aircraft Build", description: "" },
      { name: "build_series", label: "BITE Aircraft Build Series", description: "" },
      { name: "ground_date", label: "BITE Ground Data", description: "" },
    //  { name: "file_set_defected", label: "BITE Defect Fileset Flag", description: "" },
    //  { name: "file_set_defected", label: "BITE Defect Fileset Flag", description: "" },
    //  { name: "file_set_defects", label: "BITE Defect Reason", description: "" },
      { name: "operator", label: "BITE Operator ICAO Code", description: "" },
      { name: "tail", label: "BITE Aircraft Registration Name", description: "" },
      { name: "fleet", label: "BITE Fleet Type", description: "" },
    //  { name: "activity_code", label: "BITE Event Active Flag", description: "" },
    //  { name: "address", label: "BITE Event Address", description: "" },
    //  { name: "bite_code", label: "BITE Event Bite Code", description: "" },
    //  { name: "detecting_address", label: "BITE Detecting Address", description: "" },
    //  { name: "detecting_lru_type", label: "BITE Detecting LRU Type", description: "" },
    //  { name: "detecting_lru_subtype", label: "BITE Detecting LRU Subtype", description: "" },
      { name: "duration", label: "BITE Flight Duration", description: "" },
    //  { name: "first_occurrence_timestamp", label: "BITE First Occurrence Timestamp", description: "" },
    //  { name: "last_occurrence_timestamp", label: "BITE Last Occurrence Timestamp", description: "" },
    //  { name: "lru_type", label: "BITE LRU Typw", description: "" },
    //  { name: "lru_subtype", label: "BITE LRU Subtypw", description: "" },
      { name: "occurrence_count", label: "BITE Ocurrence Count", description: "" },
      { name: "air", label: "BITE Airside Event Flag", description: "" },
    //  { name: "vendor", label: "BITE Vendor Event Flag", description: "" },
    //  { name: "preflight", label: "BITE Preflight Event Flag", description: "" },
    //  { name: "health", label: "BITE Health Event Flag", description: "" },
    //  { name: "lru_group", label: "BITE LRU Group", description: "" },
      { name: "bite_group", label: "BITE Group", description: "",  
         operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' }
        ],
        valueEditorType: 'select', // see https://react-querybuilder.js.org/api/react-querybuilder/type-aliases/ValueEditorType
        values: [
          { name: '20', label: 'Reboot' },
          { name: '21', label: 'Command' },
          { name: '22', label: 'Restart' },
          { name: '24', label: 'Command Response' },
          { name: '120', label: 'Ground Reboot' },
          { name: '121', label: 'Ground Command' },
          { name: '122', label: 'Ground Restart' },
          { name: '124', label: 'Ground Command Response' }
        ], 
      },
    //  { name: "bite_exclusion", label: "BITE Exclusion Flag", description: "" },
      { name: "mmid", label: "BITE MMID", description: "" },
    //  { name: "fault_abo_message", label: "BITE Fault ABO Message", description: "" },
    //  { name: "fault_ata_id", label: "BITE Fault ATA ID", description: "" },
      { name: "lruloc_lru_type", label: "BITE LRU Location Type", description: "" },
      { name: "lruloc_lru_subtype", label: "BITE LRU Location Subtype", description: "" },
      { name: "lruloc_address", label: "BITE LRU Location address", description: "" },
      { name: "lruloc_acronym", label: "BITE LRU Location Acronym", description: "" },
      { name: "lruloc_location", label: "BITE LRU Location", description: "" },
    //  { name: "lruloc_description", label: "BITE LRU Location Description", description: "" },
      { name: "detecting_lruloc_lru_type", label: "BITE LRU Location Type", description: "" },
      { name: "detecting_lruloc_lru_subtype", label: "BITE LRU Location Subtype", description: "" },
      { name: "detecting_lruloc_address", label: "BITE LRU Address", description: "" },
      { name: "detecting_lruloc_acronym", label: "BITE LRU Acronym", description: "" },
      { name: "detecting_lruloc_location", label: "BITE LRU Location", description: "" },
    //  { name: "detecting_lruloc_description", label: "BITE LRU Description", description: "" },
      { name: "fault_system_impact", label: "BITE Fault System Impact", description: "" },
    //  { name: "event", label: "BITE Event", description: "" },
    //  { name: "event_date", label: "BITE Event Data", description: "" },
    //  { name: "last_event_date", label: "BITE Last Event Date", description: "" },
      { name: "system_health", label: "BITE System Health Flag", description: "" },
      { name: "seat_health", label: "BITE Seat Health Flag", description: "" },
    ]
  }
];






function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * not needed? 
 */
 /*
function getFieldsFromQuery(query) {
  var fields = [];
  if (query) {
    
    query['rules'].forEach((rule) => {
      if (rule['rules']) {
        fields = fields.concat(getFieldsFromQuery(rule));
      }
      else {
        fields.push(rule.field);
      }
    });
  }
  return fields;
}
*/

export default function QueryBuilder1(props) {

  logger.debug("props", props);

/*
  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(props.value?props.value:queryValue), config),
    config: config
  });
*/
 var rule_list = [
    <MenuItem key="select" value="select">Selet a data source</MenuItem>,
  ];
  
  var init_value = props.value?props.value:{};
  /*
  attribute_data.forEach((sources) => {
    if (!init_value[sources.source]) init_value[sources.source] = null; 
  });
  */
  logger.debug("init_value", init_value);

  const [tab, setTab] = useState(0);
  //const [rule, setRule] = useState("ALL");
  const [error, setError] = useState("");
  //const [json, setJSON] = useState("");
  const [querySet, setQuerySet] = useState(init_value);
  const [queryType, setQueryType] = useState("select");
  
 
  
  //var query_fields;
  //var query_fields = getFieldsFromQuery(query);
  //var new_query_fields = [];

  var fields = [];
  //var attributes = [];
  attribute_data.forEach((sources) => {
    rule_list.push(<MenuItem key={sources.source} value={sources.source}>{sources.label}</MenuItem>);

  
  
  
    sources.columns.forEach((column) => {
  
      
      var new_column = {...column};
     // new_column.name = sources['source'] + "." + new_column.name;
    //  new_column.key = sources['source'] + "." + new_column.name;
      
      /*
      //logger.debug("attribute column", column, new_column);
      var in_query = false;
      query_fields.forEach((field) => {
        if (new_column.name == field) 
        in_query = true;
      });
      */
      if (queryType === sources.source) {
        fields.push(new_column);
        
      }
      /*
      if (queryType === 'ALL') {
        fields.push(new_column);
      }
      else 
      else if (in_query) {
        fields.push(new_column);

      }
      */
    });
  });
  /*
  logger.debug("fields", fields);
  query_fields.forEach((field) => {
    fields.push(field);
  });
  */
  /*
  const onQueryChange = useCallback((immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    logger.debug("onChange", immutableTree, config);
    setState({ ...state, tree: immutableTree, config: config });

    const jsonTree = QbUtils.getTree(immutableTree);
    console.log(jsonTree);
    props.handleChange({target: {name: props.name, value: JSON.stringify(jsonTree)}});
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }, []);
  */
  
  const handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      //setJSON(JSON.stringify(logicJSON));

    }
    setError(""); 
    setTab(newValue);
  };

  const onTextChange = useCallback((event) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    logger.debug("onTextChange", event);
    
    return;
    
    var error = false;
    var value = event.target.value;
    //setJSON(event.target.value);
    if (value.trim() != "") {

      try {
      var json = JSON.parse(event.target.value);
      }
      catch(e) {
        error = "Invalid JSON";
      }
      logger.debug("onTextChange error", error);
    }
    if (error !== false) {
      setError(error);
    }
    else {
      setError("");
      //var treeObj = checkTree(loadFromJsonLogic(json, state.config), state.config); // <- this will work same
     // logger.debug("onTextChange checktree", treeObj);

      /*
      if (treeObj) {
        setState({
          ...state,
          tree: treeObj,
        });
      }
      else if (value.trim() != "") {
          error = "Invalid structure";
      }
      */
    }
    setError(error);
  }, []);

  var displayError = '';
  if (error) displayError = error;
  else {
    // check errorsJSON
  }

  const handleQueryChange = (data) => {
    
    var tmp_query_set_data = {};
    var tmp_query_set = JSON.parse(JSON.stringify(querySet));
    tmp_query_set[queryType] = data;
    
    logger.debug("handleQueryChange", tmp_query_set);
    
    // This should only happen the first time
    
    attribute_data.forEach((sources) => {
      if (!tmp_query_set[sources['source']]) {
        tmp_query_set[sources['source']] = data;
      }
    });
    
    setQuerySet(tmp_query_set);
    
    
    props.onChange(
      {
        target: {
          name: props.name,
          value: JSON.stringify(tmp_query_set) //JSON.stringify(tmp_query_set)
        }
      }
    );
    
    // format for jsonlogic
    var tmp_query_set_json = {};
    for (const [key, value] of Object.entries(tmp_query_set)) {
      logger.debug("jsonlogic", key, value);
      tmp_query_set_json[key] = formatQuery(value, "jsonlogic") ;
    }
    
     props.onChange(
      {
        target: {
          name: props.name + "_jsonlogic",
          value: JSON.stringify(tmp_query_set_json) //JSON.stringify(formatQuery(tmp_query_set_json, "jsonlogic"))
        }
      }
    );
  };
  
  
  var query_builder;
  if (queryType != 'select')
    query_builder =<QueryBuilder fields={fields} query={querySet[queryType]} onQueryChange={handleQueryChange} />;
      
      
  logger.debug("queryType", queryType, typeof(querySet[queryType]), querySet);  

  return (
    <div>
      <Select
          labelId="query_type"
          id="query_type"
          value={queryType}
          onChange={
            (event) => {
              setQueryType(event.target.value);
            }
          }
          style={{margin: "8px 0px 8px 10px"}}
        >
         {rule_list}
      </Select>
      {queryType != 'select' &&


      <Tabs value={tab} onChange={handleTabChange} aria-label="simple tabs example">
        <Tab key="builder" label="Builder" {...a11yProps(0)} />
        <Tab key="JSON" label="JSON" {...a11yProps(1)} />
        <Tab key="JsonLogic" label="JsonLogic" {...a11yProps(6)} />
        <Tab key="JsonLogic Encoded" label="JsonLogic Encoded" {...a11yProps(6)} />
        <Tab key="SQL" label="SQL" {...a11yProps(2)} />
        
        {/*}
        <Tab label="MongoDB" {...a11yProps(3)} />
        <Tab label="CEL" {...a11yProps(4)} />
        <Tab label="SpEL" {...a11yProps(5)} />
        <Tab label="ElasticSearch" {...a11yProps(7)} />
        <Tab label="JSONata" {...a11yProps(8)} />
        */}
        
      </Tabs>
      }
            
      {/* builder */}
      <div hidden={tab !== 0} style={{paddingRight: "8px"}}>
        
        {query_builder}
        
      </div>
      
      {/* JSON */}
      <div hidden={tab !== 1} className="query-builder-result"> 
        <div>
          <pre>
            {typeof(querySet[queryType]) !== 'undefined'?formatQuery(querySet[queryType], "json"):""}
          </pre>
        </div>
      </div>
      {/* Editor - not used
      <div hidden={tab !== 1} style={{padding: "28px"}} >
        <TextField
          fullWidth
          id="filled-multiline-flexible"
          label=""
          multiline
          maxRows={20}
          value={formatQuery(querySet[queryType], 'json')}
          //onChange={handleChange}
          variant="outlined"
          inputProps={{style: {fontFamily: "Lucida Console, Courier New, monospace", fontSize: "14px"}}}
          onChange={onTextChange}
        />
        <div style={{color: "#ff0000"}}>
          {displayError}
        </div>
      </div>
      */}
      {/* JSON Logic*/}
      {/*
      <div hidden={tab !== 2} className="query-builder-result"> 
        <div>
          <pre>
            {typeof(querySet[queryType]) !== 'undefined'?JSON.stringify(formatQuery(querySet[queryType], "jsonlogic")):""}
          </pre>
        </div>
      </div>
     
      {/* JSON Logic Encoded*/}
      {/*
      <div hidden={tab !== 3} className="query-builder-result"> 
        <div>
          <pre>
            {typeof(querySet[queryType]) !== 'undefined'?JSON.stringify(formatQuery(querySet[queryType], "jsonlogic")):""}
          </pre>
        </div>
      </div>
      
      {/* SQL */}
      {/*
      <div hidden={tab !== 4} className="query-builder-result"> 
        <div>
          <pre>
             {typeof(querySet[queryType]) !== 'undefined'?formatQuery(querySet[queryType], "sql"):""}
          </pre>
        </div>
      </div>
      */}
    </div>
  );
}