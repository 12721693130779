import React from 'react';
import { Logger } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@mui/material/Grid2';
import CardReport from './CardReport6';

const logger = new Logger('ReportGrid');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

export default function ReportGrid(props) {
    logger.debug("props", props);
    const classes = useStyles();

    let showSubtitles = props.showSubtitles !== undefined ? props.showSubtitles : true;

    if (props.report_list.length) {
        return (
            <div className={classes.root}>
                <Box mt={1} mb={1}>
                    <Typography variant="h5" component="h5">
                        {props.title}
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="stretch"
                >
                    {props.report_list.map((report_details, index) => {
                        // Dynamically set category:
                        // 1. Use report_details.category for Favorites.
                        // 2. Use props.category for Recent Reports or Home.
                        const categoryName = report_details.category || props.category || '';
                        return (
                            <Grid
                                xs={12} sm={6} md={6} lg={6} xl={6}
                                key={index}
                            >
                                <CardReport
                                    title={report_details.title}
                                    subtitle={report_details.subtitle}
                                    href={report_details.href}
                                    docs_href={report_details.docs_href}
                                    target={report_details.target}
                                    image={report_details.image}
                                    customer_icon={report_details.customer_icon}
                                    tags={report_details.tags}
                                    report_environments={report_details.report_environments}
                                    report_type={report_details.report_type}
                                    showSubtitle={showSubtitles}
                                    favorite={report_details.favorite}
                                    default_operator={report_details.default_operator}
                                    size={props.size}
                                    last_visit_time={report_details.last_visit_time}
                                    category={categoryName} // Updated logic
                                    title_icon={report_details.title_icon}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    } else {
        return null;
    }
}
