let tenant_management_service_api;


var API;

if (window.location.origin.indexOf("amazonaws") > -1) {
  console.log("API Configuration", "Cloud9");
  
  tenant_management_service_api = "https://e4f81ajvp6.execute-api.us-west-2.amazonaws.com/dev"; // unused
  API = {
    endpoints: [
      {
        name: "TenantManagementServiceAPI",
        //endpoint: 'https://e4f81ajvp6.execute-api.us-west-2.amazonaws.com/dev',
        //endpoint: 'https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev',
        endpoint: 'https://uhdg0ahs3d.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2'
      },
      {
        name: "RulesEngineAPI",
        endpoint: "https://y6y33pmisl.execute-api.us-west-2.amazonaws.com/dev",
        region: 'us-west-2'
      },
      {
        name: "OriginsAPI",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins-dev.nextcloud.aero",
        SecretId: "origins-operational-dev-api-m2m-credentials",
        region: 'us-west-2'
      },
       {
        name: "OriginsAPIProd",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins.nextcloud.aero",
        SecretId: "origins-operational-api-m2m-credentials",
        region: 'us-west-2'
      },
       {
        name: "OriginsAPITest",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins-test.nextcloud.aero",
        SecretId: "origins-operational-test-api-m2m-credentials",
        region: 'us-west-2'
      },
             {
      name: "OriginsAPIDev",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins-dev.nextcloud.aero",
        SecretId: "origins-operational-dev-api-m2m-credentials",
        region: 'us-west-2'
      },

      {
        name: "FlightDataAPI",
        endpoint: "https://d-flightdata.nextcloud.aero",
        OAuth2: {
            clientId: '740o9edo92ngm6378pa1r1k6d4',
            clientSecret: '1fq53h4o8bqhikpvlm63jgu08tn0lcen5v8aptqk5hd0jht9hkp3',
            accessTokenUri: 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token',
            authorizationUri: 'https://d-flightdata.nextcloud.aero/',
            redirectUri: '',
            scopes: ['https://d-flightdata.nextcloud.aero/api/flightdata.read']
        }
      },
       {
        name: "OriginsAPIService",
        endpoint: "https://api.origins-dev.nextcloud.aero",
        OAuth2: {
            clientId: '19p7nfmkvpigorhp3qamc4m14u',
            clientSecret: '1211uf1u551koqta6psish4tbsgfs5vup3qacnvqv7i429mignni',
            accessTokenUri: 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token',
            authorizationUri: 'https://api.origins-dev.nextcloud.aero',
            scopes: ['https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev/origins.operational.readonly']
            
            /*
            const tokenUrl = 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token';
            const client_id = '19p7nfmkvpigorhp3qamc4m14u';
            const client_secret = '1211uf1u551koqta6psish4tbsgfs5vup3qacnvqv7i429mignni';
            const scope = 'https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev/origins.operational.readonly'
            const grant_type = 'client_credentials';
            */
        }
      }
    ]
  };
}  
else if (window.location.origin.indexOf("-dev.nextcloud.aero") > -1) {
  console.log("API Configuration", "DEV");
  tenant_management_service_api = "https://api.console.nextcloud.aero"; // unused
  API = {
    endpoints: [
      {
        name: "TenantManagementServiceAPI",
        //endpoint: 'https://e4f81ajvp6.execute-api.us-west-2.amazonaws.com/dev',
        //endpoint: 'https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev',
        endpoint: 'https://uhdg0ahs3d.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2'
      },
      {
        name: "RulesEngineAPI",
        endpoint: "https://y6y33pmisl.execute-api.us-west-2.amazonaws.com/dev",
        region: 'us-west-2'
      },
      {
        name: "OriginsAPI",
        endpoint: process.env.REACT_APP_ORIGINS_API_ENDPOINT,
        SecretId: "origins-operational-dev-api-m2m-credentials",
        region: 'us-west-2'
      },
      {
        name: "OriginsAPIProd",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins.nextcloud.aero",
        SecretId: "origins-operational-api-m2m-credentials",
        region: 'us-west-2'
      },
       {
        name: "OriginsAPITest",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins-test.nextcloud.aero",
        SecretId: "origins-operational-test-api-m2m-credentials",
        region: 'us-west-2'
      },
             {
      name: "OriginsAPIDev",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins-dev.nextcloud.aero",
        SecretId: "origins-operational-dev-api-m2m-credentials",
        region: 'us-west-2'
      },
      {
        name: "FlightDataAPI",
        endpoint: "https://flightdata.nextcloud.aero",
        OAuth2: {
            clientId: '5nrqpai18j795i1as158siae5n',
            clientSecret: '1hu6mn137880s6iiilo6gm218j1tv7nlj1mhtqtkl6rll1g1i012',
            accessTokenUri: 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token',
            authorizationUri: 'https://flightdata.nextcloud.aero/',
            redirectUri: '',
            scopes: ['https://flightdata.nextcloud.aero/api/flightdata.read']
        }
      },
      {
        name: "OriginsAPIService",
        endpoint: "https://api.origins-dev.nextcloud.aero",
        OAuth2: {
            clientId: '19p7nfmkvpigorhp3qamc4m14u',
            clientSecret: '1211uf1u551koqta6psish4tbsgfs5vup3qacnvqv7i429mignni',
            accessTokenUri: 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token',
            authorizationUri: 'https://api.origins-dev.nextcloud.aero',
            scopes: ['https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev/origins.operational.readonly']
            
            /*
            const tokenUrl = 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token';
            const client_id = '19p7nfmkvpigorhp3qamc4m14u';
            const client_secret = '1211uf1u551koqta6psish4tbsgfs5vup3qacnvqv7i429mignni';
            const scope = 'https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev/origins.operational.readonly'
            const grant_type = 'client_credentials';
            */
        }
      }
    ]
  };
}
else if (window.location.origin.indexOf("-test.nextcloud.aero") > -1) {
  console.log("API Configuration", "TEST");
  tenant_management_service_api = "https://api.console.nextcloud.aero"; // unused
  API = {
    endpoints: [
      {
        name: "TenantManagementServiceAPI",
        //endpoint: 'https://e4f81ajvp6.execute-api.us-west-2.amazonaws.com/dev',
        //endpoint: 'https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev',
        endpoint: 'https://uhdg0ahs3d.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2'
      },
      {
        name: "RulesEngineAPI",
        endpoint: "https://y6y33pmisl.execute-api.us-west-2.amazonaws.com/dev",
        region: 'us-west-2'
      },
      {
        name: "OriginsAPI",
        endpoint: process.env.REACT_APP_ORIGINS_API_ENDPOINT,
        SecretId: "origins-operational-dev-api-m2m-credentials",
        region: 'us-west-2'
      },
      {
        name: "OriginsAPIProd",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins.nextcloud.aero",
        SecretId: "origins-operational-api-m2m-credentials",
        region: 'us-west-2'
      },
      {
        name: "OriginsAPITest",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins-test.nextcloud.aero",
        SecretId: "origins-operational-test-api-m2m-credentials",
        region: 'us-west-2'
      },
             {
      name: "OriginsAPIDev",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins-dev.nextcloud.aero",
        SecretId: "origins-operational-dev-api-m2m-credentials",
        region: 'us-west-2'
      },
      {
        name: "FlightDataAPI",
        endpoint: "https://flightdata.nextcloud.aero",
        OAuth2: {
            clientId: '5nrqpai18j795i1as158siae5n',
            clientSecret: '1hu6mn137880s6iiilo6gm218j1tv7nlj1mhtqtkl6rll1g1i012',
            accessTokenUri: 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token',
            authorizationUri: 'https://flightdata.nextcloud.aero/',
            redirectUri: '',
            scopes: ['https://flightdata.nextcloud.aero/api/flightdata.read']
        }
      },
      {
        name: "OriginsAPIService",
        endpoint: "https://api.origins-dev.nextcloud.aero",
        OAuth2: {
            clientId: '19p7nfmkvpigorhp3qamc4m14u',
            clientSecret: '1211uf1u551koqta6psish4tbsgfs5vup3qacnvqv7i429mignni',
            accessTokenUri: 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token',
            authorizationUri: 'https://api.origins-dev.nextcloud.aero',
            scopes: ['https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev/origins.operational.readonly']
            
            /*
            const tokenUrl = 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token';
            const client_id = '19p7nfmkvpigorhp3qamc4m14u';
            const client_secret = '1211uf1u551koqta6psish4tbsgfs5vup3qacnvqv7i429mignni';
            const scope = 'https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev/origins.operational.readonly'
            const grant_type = 'client_credentials';
            */
        }
      }
    ]
  };
}
else {
  console.log("API Configuration", "NOT Cloud9 or DEV");
  tenant_management_service_api = "https://api.console.nextcloud.aero"; // unused
  API = {
    endpoints: [
      {
        name: "TenantManagementServiceAPI",
        //endpoint: 'https://e4f81ajvp6.execute-api.us-west-2.amazonaws.com/dev',
        //endpoint: 'https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev',
        endpoint: 'https://uhdg0ahs3d.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2'
      },
      {
        name: "RulesEngineAPI",
        endpoint: "https://y6y33pmisl.execute-api.us-west-2.amazonaws.com/dev",
        region: 'us-west-2'
      },
      {
        name: "OriginsAPI",
        endpoint: process.env.REACT_APP_ORIGINS_API_ENDPOINT,
        SecretId: "origins-operational-api-m2m-credentials",
        region: 'us-west-2'
      },
          {
        name: "OriginsAPIProd",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins.nextcloud.aero",
        SecretId: "origins-operational-api-m2m-credentials",
        region: 'us-west-2'
      },
       {
        name: "OriginsAPITest",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins-test.nextcloud.aero",
        SecretId: "origins-operational-test-api-m2m-credentials",
        region: 'us-west-2'
      },
      {
      name: "OriginsAPIDev",
        //endpoint: "https://api.origins.nextcloud.aero",
        //SecretId: "origins-operational-api-m2m-credentials",
        endpoint: "https://api.origins-dev.nextcloud.aero",
        SecretId: "origins-operational-dev-api-m2m-credentials",
        region: 'us-west-2'
      },
      {
        name: "FlightDataAPI",
        endpoint: "https://flightdata.nextcloud.aero",
        OAuth2: {
            clientId: '5nrqpai18j795i1as158siae5n',
            clientSecret: '1hu6mn137880s6iiilo6gm218j1tv7nlj1mhtqtkl6rll1g1i012',
            accessTokenUri: 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token',
            authorizationUri: 'https://flightdata.nextcloud.aero/',
            redirectUri: '',
            scopes: ['https://flightdata.nextcloud.aero/api/flightdata.read']
        }
      },
      {
        name: "OriginsAPIService",
        endpoint: "https://api.origins.nextcloud.aero",
        OAuth2: {
            clientId: '17q62bornu0310buhnr7q7gh2d',
            clientSecret: '1vb752uqkfacn07bisgl4e2lmum6h3fe7bvfaeln7fi4s7icdfd',
            accessTokenUri: 'https://panasky-panasonicaero-idam-userpool.auth.us-west-2.amazoncognito.com/oauth2/token',
            authorizationUri: 'https://api.origins.nextcloud.aero',
            scopes: ['https://682oosx15i.execute-api.us-west-2.amazonaws.com/dev/origins.operational.readonly']
        }
      }
    ]
  };
}

export default API;
