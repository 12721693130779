import React, { useState, useEffect, useRef }  from 'react';
//import ReactDOMServer       from 'react-dom/server';
import { Logger }           from 'aws-amplify';

const logger = new Logger('Icon');

export default function Icon(props) {
    logger.debug(props.name);

    const ImportedSvgRef = useRef(null);
    const [imported, setImported] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    logger.debug(props, ImportedSvgRef.current);
    useEffect(() => {
        
        logger.debug("useeffect", props.name, ImportedSvgRef.current);
        
        const importSvg = async () => {
            try {logger.debug("trying");
                ImportedSvgRef.current = (await import(`assets/icons/${props.name}.svg`)).default;
                setImported(ImportedSvgRef.current);
                logger.debug("done", ImportedSvgRef.current);
                
            } catch (err) {
                logger.debug("catch", err);
                ImportedSvgRef.current = null;
                setImported(null);
                setError(err);
            } finally {
                logger.debug("finally");
                setLoading(false);
            }
        };
        importSvg();
        
    }, [props.name]);
    if (imported) return <img style={{height: "40px", width: "40px" }} src={imported} />;

    return <span className={"material-symbols-outlined"} style={{fontSize: 32, fontWeight: 300, color: "#1c3f62"}}>{props.name}</span>;
}