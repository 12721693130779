/**
 * Search landing page taking in a search parameter and redirecting if the search matches a path
 */ 
import React, { useState, useEffect, useContext } from 'react';
import {makeStyles }        from '@material-ui/core/styles';
import View                     from 'pac-responsive-ui-framework/core/View';
import Home                 from '../views/Home';
import { 
    setReportList,
    searchReports, 
    getReportList, 
    setFavoriteReports 
}                           from '../data/ReportList';
import ReportGrid           from '../components/ReportGrid6';
import News                 from '../components/News';
import AppContext           from 'pac-responsive-ui-framework/core/AppContext';
import { useHistory }           from "react-router-dom";

import { 
  Route, 
  useParams,
}                           from 'react-router-dom';

import { Logger } from 'aws-amplify';

const logger = new Logger('views/Search');

export default function Search(props) {
  let history = useHistory();
  const app_context   = useContext(AppContext);
  setReportList(app_context.state.reports_list);
  setFavoriteReports(app_context.state.favorite_reports);
  
  const useStyles = makeStyles({
      container: {
        paddingLeft: 96,
      },
       Box: {
        paddingTop: 72,
        width: "100%"
      },
      root: {
        flexGrow: 1,
      },
     
  });
  const classes = useStyles();
  let { search } = useParams();
  search = search.replace(/^Search: /, '');
  logger.debug(search);
  
  if (search) {
      console.log("AppSearchTitle", search);
      var ReportListData = searchReports(search, 1000, app_context.state);
      console.log("AppSearchTitle", search, ReportListData);
      
      var report_grid =<ReportGrid report_list={ReportListData}  /> 
      var message="Dashboards matching the search term \"" + search + "\".";
      if (ReportListData.length === 0) {
        report_grid = <ReportGrid title="Reports" report_list={ getReportList([], app_context.state)} /> 
        message = "There were no reports matching the search term \"" + search + "\".  " +
                  "You can try \"gcs\" or \"log\" or \"antenna\" or view the list of reports below.";
      }
      else if (ReportListData.length === 1  && ReportListData[0].target != "_blank") {
         history.push(ReportListData[0].href);
      }
     
      return (
       <View title={"Search: " + search}>
          <News message={message} />
          {report_grid}
        </View>
      );
    }
    else {
      // this is not working.  could be the router in reports.js
      return (
      <Route path="\">
          <Home />
        </Route>
        );
    }
  }






