import OauthService from './OauthService';
import BaseService from './BaseService';
import { Logger } from 'aws-amplify';
import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';

const logger = new Logger('DataFiltetService');

const checkboxes = [
    "enabled"
];


export default class DataFilterService extends BaseService {

    constructor() {
        //let apiName = 'OriginsAPI';
        //let collectionPath = '/services/datafilter';
        //super(apiName, collectionPath);
        
        let apiName = 'RulesEngineAPI';
        let collectionPath = '/data_filter';
        super(apiName, collectionPath);
    }


    formatRowData(data) {
        logger.debug("formatRowData", data, data.data);

        /*
        data.data.forEach((element) => {
            if (!element['last_updated_date']) element['last_updated_date'] = element['created_date'];
            if (element['created_date']) element['created_date'] = element['created_date'].substring(0, 19).replace('T', ' ');
            if (element['last_updated_date']) element['last_updated_date'] = element['last_updated_date'].substring(0, 19).replace('T', ' ');

            checkboxes.forEach((checkbox)=> {
                if (element[checkbox] === undefined) element[checkbox] = false;
            });
        });
        */
       // data.data = [];
        data.data.push({
            "enabled": true,
            "filter_name": "Test 1",
            "filter_description": "Test 1",
            "filter_query": JSON.stringify({
                "tsyslog_bite": {
                    "id": "db403df0-6586-4e26-8fc5-34d574fb8393",
                    "rules": [
                        { 
                            "id": "0318fd6f-080e-4a8a-b5a2-33bc4126e793",
                            "field": "operator",
                            "operator": "=",
                            "valueSource": "value",
                            "value": "TAI"
                        },
                        { 
                            "id": "47bcf637-14e6-49cc-9fc9-15472540c88d",
                            "field": "occurrence_count",
                            "operator": ">=",
                            "valueSource": "value",
                            "value": "1"    
                            
                        },    
                        {
                            "id": "b1a2347d-172c-4a42-943a-414535fe9bbb",
                            "field": "mmid",
                            "operator": "=",     
                            "valueSource": "value",
                            "value": "196-001-00023"    
                        },    
                        { 
                            "id": "128a4e82-7799-4693-bb08-5e69e29a0493",
                            "rules": [
                                {          
                                    "id": "2869f2c3-cf48-49f7-9e23-81644e08b005",          
                                    "field": "ground_date",          
                                    "operator": "=",          
                                    "valueSource": "value",
                                    "value": "1"
                                },
                                {
                                    "id": "fa24c783-0326-4b5f-89a2-7188db40d4b2",
                                    "field": "air",
                                    "operator": "=",
                                    "valueSource": "value",
                                    "value": "1"
                                },    
                                {
                                    "id": "d46e9333-dea5-446c-b2ce-90c26541fe44",
                                    "field": "bite_group",
                                    "operator": "!=",
                                    "valueSource": "value",
                                    "value": "22"
                                }
                            ],
                            
                            "combinator": "or",
                            "not": false
                        }  
                    ],
                    "combinator": "and",
                    "not": false
                } 
                
            }),
            "created_date": 1733163163.408325,
            "created_by": "brian.davis@pansonic.aero",
            "last_updated_date": 1733163163.408354,
            "last_updated_by": "brian.davis@pansonic.aero"
        });
        return data.data;
    }
    

    getMetaData(data) {
        return {
            pagination: false,
            total_records: data.length
        };
    }

    getCloneRecord(record, ) {
        record['filter_name'] = record['filter_name'] + ' (clone)';
        record['original_title'] = "";
        return record;
    }
    
    async delete(data, recordID) {
        
    }

    /**
     * {
     *   "enabled": false,
     *   "filter_name": "filter",
     *   "filter_description": "Updated description",
     *   "filter_query": "{updated query}",
     *   "user": "someother_user@pansonic.aero"
     * }
     */
    async save(data, metaData) {
        logger.debug("save1", data, metaData);

        //check the PK and set bool to true/false
        checkboxes.forEach((checkbox)=> {
            if (data[checkbox])
                data[checkbox] = true;
            else
                data[checkbox] = false;
        });
        
        var new_data = {};
        new_data['user'] = metaData.app_state.user.session.login_name;
        new_data['filter_name'] = data['filter_name'];
        new_data['filter_description'] = data['filter_description']?data['filter_description']:"";
        new_data['filter_query'] = data['filter_query'];
        new_data['enabled'] = data['enabled'];
        
        

        logger.debug("save2", data, new_data, metaData);
        var response = null;
        if (data['last_updated_date']) { //update because it already had a date
            logger.debug("save update", data);
            try {
                var results = await this.update("", new_data);
                response = {
                    success: true,
                    updated: true,
                    data: results
                };
            }
            catch(err) {
            //.catch((err) => {
                logger.debug('save: update record failed', err, new_data);
                 response = {
                    success: false,
                    updated: false,
                    response: {
                        data: {
                            error: "An error occurred"
                        }
                    }
                };

            //});
            }
        }
        else { //insert
            logger.debug("save insert", new_data);
            await this.add(new_data)
            .then((results) => {
                logger.debug("save: insert record", results);
                if (results.success == false) {
                    response =  {
                        success: false,
                        updated: false,
                        response: {
                            data: {
                                error: results.error.message
                            }
                        }
                    };
                }
                else {
                    response = {
                        success: true,
                        updated: true
                    };
                }
            })
            .catch((err) => {
                logger.debug("save: insert record error1", err);
                response = {
                    success: false,
                    updated: false,
                    response: {
                        data: {
                            error: err.message
                        }
                    }
                };
            });
        }
        return response;
    }


    getColumnDefs() {
        var column_width = 100;

        return [
        {
            headerName: 'History',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "created_date",
                    headerName: "Creation Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "created_by",
                    headerName: "Created By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "last_updated_date",
                    headerName: "Last Updated Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    newline: true,
                    sort: "desc"
                },
                {
                    field: "last_updated_by",
                    headerName: "Last Updated By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    formType: "readonly",
                    columnGroupShow: "open"
                },
            ]
        },
        {
            headerName: 'Details',
            defaultExpanded: true,
            children: [
                {
                    field: "enabled",
                    headerName: "Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: 1,
                },
                {
                    field: "filter_name",
                    headerName: "Filter Name",
                    primaryKey: true, // makes the field read only when updating but read write when inserting
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    required: true,
                    newline: true
                },
                {
                    field: "filter_description",
                    headerName: "Description",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "multiline",
                    required: false
                },
                {
                    field: "filter_query",
                    headerName: "Filter Query",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "querybuilder",
                    required: false
                },
                {
                    /** Note that this field should match the querybuilder name 
                     *  with "_jsonlogic" appended to it.
                     * The value for JSONLogic will be set when the filterQuery
                     * value is saved. 
                     */
                    field: "filter_query_jsonlogic",
                    headerName: "JSONLogic",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "hidden",
                    required: false
                },
            ]
        },
        ];
    }
}
